<template>
	<div>
		<S2SCard title="Addresses">
			<v-btn slot="toolbar-content" :to="{ name: 'organisation-address-create' }" color="accent" @click="setSelectedAddress()" cy-data="add-address"
				>Add</v-btn
			>
			<v-data-table :headers="headers" :items="addresses" :loading="status.loading" :mobile-breakpoint="0" class="elevation-1">
				<template v-slot:item.viewAddress="{ item }">
					<v-btn color="accent" @click="viewAddressMap(item)" text>
						<v-icon v-if="item.isPrimary">my_location</v-icon>
						<v-icon v-else>map</v-icon>
					</v-btn>
				</template>
				<template v-slot:item.isPrimary="{ item }">
					<v-checkbox
						class="mt-0"
						:input-value="item.isPrimary"
						color="primary"
						hide-details
						@change="onUpdate($event, 'isPrimary', item)"
						@click.once.stop
					></v-checkbox>
				</template>
				<template v-slot:item.isFavourite="{ item }">
					<v-checkbox
						class="mt-0"
						:input-value="item.isFavourite"
						color="primary"
						hide-details
						@change="onUpdate($event, 'isFavourite', item)"
						@click.once.stop
					></v-checkbox>
				</template>
				<template v-slot:item.isBilling="{ item }">
					<v-checkbox
						class="mt-0"
						:input-value="item.isBilling"
						color="primary"
						hide-details
						@change="onUpdate($event, 'isBilling', item)"
						@click.once.stop
					></v-checkbox>
				</template>
				<template v-slot:item.isCollection="{ item }">
					<v-checkbox
						class="mt-0"
						:input-value="item.isCollection"
						color="primary"
						hide-details
						@change="onUpdate($event, 'isCollection', item)"
						@click.once.stop
					></v-checkbox>
				</template>
				<template v-slot:item.isDelivery="{ item }">
					<v-checkbox
						class="mt-0"
						:input-value="item.isDelivery"
						color="primary"
						hide-details
						@change="onUpdate($event, 'isDelivery', item)"
						@click.once.stop
					></v-checkbox>
				</template>
				<template v-slot:item.editAddress="{ item }">
					<v-btn @click="onAddressClick(item)" text><v-icon>edit</v-icon></v-btn>
				</template>

				<template v-slot:item.address="{ item }">{{ aggregateAddress(item) }}</template>
			</v-data-table>

			<!-- MAP DIALOG -->
			<v-dialog v-model="mapDialog" scrollable fullscreen persistent max-width="500px" transition="dialog-transition">
				<v-card>
					<v-toolbar dark color="primary">
						<v-btn icon dark @click="mapDialog = false">
							<v-icon>close</v-icon>
						</v-btn>
						<v-toolbar-title>
							Address Location:
							<h3 class="d-inline-flex mr-2">{{ mapAddress.name }}</h3>
							<div class="attributes d-inline-flex">
								<small v-if="mapAddress.isPrimary">| Primary</small>
								<small v-if="mapAddress.isFavourite">| Favourite</small>
								<small v-if="mapAddress.isBilling">| Billing</small>
								<small v-if="mapAddress.isCollection">| Collection</small>
								<small v-if="mapAddress.isDelivery">| Delivery</small>
							</div>
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
					<leaflet-map v-if="mapDialog" v-bind:lat-lng.sync="dialogLatLng" cy-data="leaflet-map"></leaflet-map>
				</v-card>
			</v-dialog>
		</S2SCard>

		<br />
		<!-- MAP OVERVIEW -->
		<S2SCard title="Addresses Overview" v-if="addresses.length > 0">
			<template>
				<l-map style="height: 80vh; width: 100%; z-index: 0;" :zoom="zoom" :center="center">
					<l-tile-layer :url="url" :attribution="attribution" />
					<l-marker v-for="item in addresses" :key="item.index" :lat-lng="[item.latitude, item.longitude]">
						<l-popup>
							<h3>{{ item.name }}</h3>
							<ul>
								<li v-if="item.isPrimary">Primary</li>
								<li v-if="item.isFavourite">Favourite</li>
								<li v-if="item.isBilling">Billing</li>
								<li v-if="item.isCollection">Collection</li>
								<li v-if="item.isDelivery">Delivery</li>
							</ul>
						</l-popup>
					</l-marker>
				</l-map>
			</template>
		</S2SCard>
	</div>
</template>

<script>
import Vue from "vue";

import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import L, { Marker, marker } from "leaflet";
import LeafletMap from "@/components/LeafletMap.vue";

export default Vue.extend({
	name: "Addresses",

	components: { LMap, LTileLayer, LMarker, LPopup, LeafletMap },

	props: {
		latLng: Object,
		default: { lat: null, lng: null }
	},

	computed: {
		addresses: function() {
			return this.$store.state.organisations.addresses.addresses;
		},
		status: function() {
			return this.$store.state.organisations.addresses.status;
		}
	},

	data: function() {
		return {
			headers: [
				{
					text: "Map",
					value: "viewAddress",
					align: "center"
				},
				{
					text: "Address Name",
					value: "name"
				},
				{
					text: "Delivery Notes",
					value: "note"
				},
				{
					text: "Address",
					value: "address"
				},
				{
					text: "Primary",
					value: "isPrimary"
				},
				{
					text: "Favourite",
					value: "isFavourite"
				},
				{
					text: "Billing",
					value: "isBilling"
				},
				{
					text: "Collection",
					value: "isCollection"
				},
				{
					text: "Delivery",
					value: "isDelivery"
				},
				{
					text: "Edit",
					value: "editAddress",
					align: "center"
				}
			],
			dialogLatLng: {},
			mapAddress: "",
			mapDialog: false,
			zoom: 6,
			mapAddresses: [],
			center: L.latLng(-27.728871, 24.789104),
			url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> conributors',
			marker: L.latLng(-27.728871, 24.789104),
			mapMarkers: [],
			mapOptions: {
				zoomSnap: 0.5
			}
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/addresses/fetchAddresses");
		this.setDefaultIcon();
	},

	methods: {
		setSelectedAddress(address) {
			this.$store.dispatch("organisations/addresses/setSelectedAddress", address);
		},
		onAddressClick(address) {
			this.setSelectedAddress(address);
			this.$router.push({ name: `organisation-address-edit`, params: { addressId: "" + address.id } });
		},
		aggregateAddress(address) {
			const addressValues = [`${address.streetNumber} ${address.streetName}`, address.suburb, address.city, address.province];
			const aggregatedValues = addressValues.filter(Boolean).join(", ");
			return aggregatedValues;
		},
		onUpdate(val, index, address) {
			address[index] = val;
			this.$store.dispatch("organisations/addresses/updateAddress", address);
		},
		viewAddressMap(address) {
			this.mapDialog = true;
			this.mapAddress = address;
			this.dialogLatLng = { lat: address.latitude, lng: address.longitude };
		},
		setDefaultIcon() {
			delete L.Icon.Default.prototype._getIconUrl;
			L.Icon.Default.mergeOptions({
				iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
				iconUrl: require("@/assets/ic_Map Marker.png"),
				shadowUrl: require("leaflet/dist/images/marker-shadow.png")
			});
		}
	}
});
</script>

<style lang="scss" scoped>
.hover {
	cursor: pointer;
}
</style>
